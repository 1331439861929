import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";

const TeamPageTemplate = ({ pageContext, data }) => {

  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: `https://www.spinecentre.com.hk${data.firestore.page.content.headerImage}`
        }
      ]
    }}>
      <PageHeader
        title={data.firestore.page.content[pageContext.locale]['headerTitle']}
        imageUrl={data.firestore.page.content.headerImage}
      />
      <main className="my-12 flex flex-col justify-center items-center mx-auto">
        <h1 className="text-coconut font-semibold text-3xl lg:text-4xl">{data.firestore.page.content[pageContext.locale]['title']}</h1>
        <hr className="border-mango border w-8 my-6" />
        <p className="text-brick text-lg lg:text-xl text-center max-w-5xl mx-4">{data.firestore.page.content[pageContext.locale]['slogan']}</p>
        <section className="max-w-5xl flex space-x-0 flex-col items-center justify-evenly md:items-start md:flex-row md:flex-wrap md:space-x-4">
          {
            data.firestore.chiropractors.map(chiropractor => (
              <div className={`flex-col justify-between items-center mt-16 w-4/5 lg:w-1/4`}>
                <Link to={chiropractor.href}>
                  <img className="rounded-md" src={chiropractor.image} alt={chiropractor.name} />
                </Link>
                <Link to={chiropractor.href}>
                  <h1 className="text-coconut text-center font-medium text-xl lg:text-2xl mb-2 mt-4">{chiropractor.name}</h1>
                  <h3 className="text-brick text-lg lg:text-xl text-center">{chiropractor.qualifications}</h3>
                  <h3 className="text-brick text-lg lg:text-xl text-center mb-8">{chiropractor.title}</h3>
                </Link>
              </div>
            ))
          }
        </section>
      </main>
    </Layout>
  )
}

export default TeamPageTemplate

export const query = graphql`
  query TeamPageTemplateQuery($pageId: ID, $locale: String!) {
    firestore {
      page(id: $pageId) {
        content
      }
      chiropractors(locale: $locale) {
        id
        name
        title
        qualifications
        description
        href
        image
      }
    }
  }
`